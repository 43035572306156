@import 'Variables.scss';
@import '~react-md/dist/react-md';

@include react-md-utils;

* {
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  height: auto;
  
}

label {
  pointer-events: none;
}

a {
  text-decoration: none;
  color: #333;
}
.carousel  {
  margin:auto;
  .slide {
    background: transparent!important;
  }
}