@import 'Construction.scss';
@import 'ProductModal.scss';
@import 'Collections.scss';

* {
  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    &::selection {
      color: none;
      background: none;
    }
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

main {
  padding: 2em 0;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  max-width: 1700px;
  margin: auto;
}

input,
textarea {
  padding: 0.5em 0.5em;
  border: 2px solid rgba(117, 115, 115, 0.6);
  border-radius: 4px 0 0 4px;
  transition: all 0.2s ease;
  font-size: 1.1rem;
}

input:focus,
input:active {
  border-color: rgba(117, 115, 115, 1);
  outline-width: 0;
}

button {
  cursor: pointer;
  background-color: #7a171e;
  border: 2px solid #7a171e;
  border-style: none;
  border-radius: 4px;
  color: #eee;
  padding: 0.6em 2em;
  font-size: 1.1rem;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.contact-form {
  // border: 1px solid red;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;

    input,
    textarea {
      margin: 0.5em 0;
      width: 100%;
      border-radius: 4px;
    }

    button {
      width: 50%;
      margin: auto;
    }
  }
}

label {
  pointer-events: none;
}

@media (min-width: 800px) {
  main {
    padding: 6em auto;
  }
}
