.construction-wrapper {
    .modal-open {
        // transition: top 0;
        display: flex;
    }

    .modal-closed {
        display: none;
    }

    .modal {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        margin: auto;
        transition: all 0.2s ease;
        align-self: center;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .modal-body {
            background-color: #eee;
            width: 80%;
            height: 80%;
            border-radius: 6px;
            position: relative;
            padding: 1em 0;
        }

        .close {
            &:hover {
                opacity: 0.8;
            }

            border-radius: 50%;
            border: none;
            width: 40px;
            height: 40px;
            z-index: 10;
            line-height: 50px;
            background-color: #7a171e;
            position: absolute;
            right: -20px;
            top: -10px;
            text-align: center;
            cursor: pointer;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);

            svg {
                font-size: 1.5em;
                color: #eee;
            }
        }
    }

    .content-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 95%;
        height: 100%;

        margin: auto;

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
            /* make scrollbar transparent */
        }

        overflow-y: scroll;

        .item {
            width: 500px;
            height: 500px;
            margin: .5em;

            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
            }
        }
    }
}

@media (max-width: 1200px) {
    .construction-wrapper {
        .content-wrapper {
            align-items: center;
            justify-content: center;
        }

    }
}



@media (max-width: 700px) {
    .construction-wrapper {
        .content-wrapper {
            width: 100%;

            .item {
                width: 400px;
                height: 400px;
            }
        }

        .modal {
            .close {
                right: 0em;
            }

            .modal-body {
                width: 95% !important;
            }
        }
    }
}