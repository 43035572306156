.construction-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: row;

    .toggle {
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

    }

    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            opacity: 0.4;
        }
    }


    .left {
        background-image: url('../assets/subtle-light-patterns.jpg');
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image-wrapper {
            width: 60%;

            h2 {
                text-align: center;
                font-weight: 400;
            }

            img {
                width: 100%;
            }

            strong {
                font-weight: 700;
                font-style: italic;
            }
        }

        .icon-wrapper {
            display: flex;
            width: 50%;
            justify-content: space-evenly;
            padding: 2em 0;

            svg {
                font-size: 4.5rem;
                color: #7a171e;
                transition: all 0.2s ease;

                &:hover {
                    color: rgb(117, 115, 115);
                }
            }
        }


    }

    .right {
        display: flex;
        flex: 1.5;
        flex-direction: column;
        justify-content: center;

        background-image: url('../assets/undraw_instant_support.svg');
        background-repeat: no-repeat;
        background-position: center;

        .section-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: rgba(255, 255, 255, 0.8);
        }

        section {
            position: relative;

            h1 {
                font-family: 'Open Sans', sans-serif;
                margin: 1em auto 2em auto;
                text-align: center;
                font-size: 3rem;
            }
        }

        .clock-wrapper {
            display: flex;
            justify-content: space-evenly;
            z-index: 1;

            .progress-wrapper {
                display: flex;
                flex-direction: column;
                margin: auto 1em;

                h3 {
                    margin: 2em auto;
                }
            }
        }

        .form-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;

            h3 {
                font-size: 1.5rem;
                text-align: center;
            }

            form {
                display: flex;
                justify-content: center;
                width: 80%;
                margin: auto;
                align-items: center;

                label {
                    display: none;
                }

                input {
                    border-right: none;
                    width: 50%;
                }

                input:focus,
                input:active {
                    width: 100%;

                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .construction-wrapper {
        flex-direction: column;
        height: 100vh;

        .left {
            flex: 1;
            background: none;
            align-items: center;
            justify-content: center;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            margin: 0;
        }

        .right {
            flex: 1;
            height: 50%;
            margin: 0;
            background: none;

            .section-wrapper {
                height: 100%;
                padding-bottom: 5em;
                background: none;
            }
        }
    }
}

@media (max-width: 750px) {
    .construction-wrapper {
        height: auto;

        .left {
            margin-bottom: 3em;
        }

        .right {
            flex-direction: column-reverse;
            background-image: none;

            section {
                h1 {
                    display: none;
                }
            }

            .clock-wrapper {
                flex-direction: column;
            }

            .form-wrapper {
                width: 80%;
                margin: 2em 0;
            }
        }
    }
}

@media (min-width: 1200px) {
    .construction-wrapper {
        .right {
            box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.2);
        }
    }
}